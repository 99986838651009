<template>
  <FullScreenDialog
    v-model="departmentDialog"
    title="Reparto"
    :routeFather="pathToGoBack"
  >
    <template v-slot:toolbar-extension>
      <v-tabs v-model="tab" align-with-title>
        <v-tabs-slider color="primary"></v-tabs-slider>
        <v-tab
          v-for="tab in departmentTabs"
          :key="tab.key"
        >{{ tab.title }}</v-tab>
      </v-tabs>
    </template>
    <template v-slot:content>
      <v-tabs-items v-model="tab">
        <v-tab-item v-for="tabItem in departmentTabs" :key="tabItem.key">
          <v-card flat>
            <component :is="tabItem.component"></component>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </template>
    <template v-slot:footer-actions>
      <v-spacer></v-spacer>
      <v-btn
        text
        color="error"
        @click="$router.push({path: pathToGoBack})"
      >Annulla</v-btn>
      <v-btn
        text
        color="default"
        :disabled="!formValid"
        @click="submitForm"
        :loading="loading"
      >Salva</v-btn>
    </template>
  </FullScreenDialog>
</template>

<script>
import FullScreenDialog from "@/components/common/FullScreenDialog.vue";
import GeneralForm from "@/components/areas/registries/departments/form/GeneralFormTab.vue"
import departmentsService from '@/services/departments/departments.service.js'
import departmentForm from '@/services/departments/department.form.js'

export default {
  name: "DepartmentsRegistryNewForm",
  components: {
    FullScreenDialog,
    GeneralForm
  },
  data: function() {
    return {
      tab: null,
      departmentDialog: this.openDialog,
      departmentTabs: [
        {
          key: 0,
          title: "Generale",
          name: "general",
          component: "GeneralForm"
        }
      ],
      formValid: false,
      department: undefined,
      originalUrl: window.location.pathname,
      loading: false
    };
  },
  mounted() {
    this.changeTabBasedOnProp(this.tabName)

    let self = this
    departmentForm.resetDepartment()
    departmentForm.on('update', function(data) {
      self.department = data.department
    })

    departmentForm.on('valid-change', function(data) {
      self.formValid = data
    })
  },
  props: {
    openDialog: {
      type: Boolean,
      default: false
    },
    pathToGoBack: {
      type: String,
      default: "/warehouse"
    },
    tabName: {
      type: String,
      default: 'general'
    }
  },
  methods: {
    changeTabBasedOnProp(tabName) {
      for(let i = 0; i < this.departmentTabs.length; i++) {
        if(this.departmentTabs[i].name == tabName) {
          this.tab = i
          break
        }
      }
    },
    submitForm() {
      this.loading = true
      departmentsService.create(this.department).then((result) => {
        this.loading = false
        this.$router.push({path: this.pathToGoBack})
      })
    }
  },
  watch: {
    openDialog(newVal) {
      this.departmentDialog = newVal;
    },
    tab(newVal) {
      try {
        window.history.pushState("Tab", "Tab", this.originalUrl + '?tabName=' + this.departmentTabs[newVal].name);
      } catch(err) {
        console.log('try setting the tab name but something goes wrong')
      }
    }
  },
  computed: {
  }
};
</script>